<template>
  <!-- Event View Section -->
  <section class="board-view-section">
    <div class="board-view">
      <div class="view-header">
        <h2 class="title">{{eventInfo.name}}</h2>
        <div class="header-info">
          <ul>
            <li class="date">
              <strong>
                {{ getDateString(eventInfo.startDate.replace(/\./g, '-'), eventInfo.endDate.replace(/\./g, '-')) }}
              </strong>
            </li>
            <template>
              <li class="v-bar">{{eventInfo.placeName || eventInfo.placeDetail}}</li>
            </template>
            <li class="v-bar">{{getApplyUser(eventInfo.targets)}}</li>
          </ul>
          <ul class="writer">
            <li>{{eventInfo.adminName}}</li>
            <li class="v-bar">{{format.date(eventInfo.insertDate.replace(/\./g, '-'))}}</li>
          </ul>
        </div>
      </div>
      <div class="view-body">
        <!-- <div v-html="editorContent"></div> -->
        <BaseViewer :value="editorContent"></BaseViewer>
        <!-- 주의 사항 -->
        <div v-if="eventInfo.precaution || eventInfo.precaution2 || eventInfo.precaution3" class="precautions-section grey-box">
          <ul class="bullet-circle-list">
            <li v-if="eventInfo.precaution">{{eventInfo.precaution}}</li>
            <li v-if="eventInfo.precaution2">{{eventInfo.precaution2}}</li>
            <li v-if="eventInfo.precaution3">{{eventInfo.precaution3}}</li>
          </ul>
        </div>
        <!-- 주의 사항 -->
      </div>
    </div>
    <div class="news_share">
      <button class="share_link" @click="kakaoLink(eventInfo.name)"><span class="ico_kakao">카카오 공유</span></button>
      <button class="share_link" @click="facebookLink"><span class="ico_facebook">페이스북 공유</span></button>
      <button class="share_link" @click="copyLink"><span class="ico_link">링크 공유</span></button>
      <!-- <button class="share_link"><span class="ico_disk">파일 다운</span></button> -->
    </div>
    <!-- Paging -->
    <div class="paging-area board">
      <div class="btn_bottom_wrap">
        <button type="button" class="btn-ghost" @click="historyBack">{{$t('content.news.detail.list_view')}}</button>
        <a
          v-if="eventInfo.externalUrl"
          :href="eventInfo.externalUrl"
          target="_blank"
          title="새창"
        >
          <button
            class="btn-basic"
            :disabled="invaildedDate"
            :class="{ disabled: invaildedDate }"
          >{{invaildedDate ? $t('content.event.Event.txt14') : $t('content.event.Event.txt15')}}</button>
        </a>
        <button
          v-else
          @click="application"
          :disabled="applicated.invalided"
          :class="{ disabled: applicated.invalided,
            'btn-ghost': applicated.isBtnGhost,
            'btn-basic': !applicated.isBtnGhost }"
        >{{applicated.text}}</button>
      </div>
    </div>
    <!-- // Paging -->
  </section>
  <!-- // Event View Section -->
</template>

<script>
import EventDetail from '@/views/event/EventDetail.vue';

export default {
  name: 'MobileEventDetail',
  extends: EventDetail,
};
</script>
